import React, { useContext, useEffect, useState } from 'react'
import { Button, Alert, Spinner, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Col, Row, Table, Form } from 'reactstrap'
import ProjectContext from '../../ProjectContext'
import notificationsService from "../../services/api/notifications-service";
import Select from 'react-select';

const FirebaseCard = (props) => {

  let message = '';
  let guid = '';
  let slug = '';
  let type = 'general';

  if (props.location.state && props.location.state.guid) {
    message = props.location.state.message;
    guid = props.location.state.guid;
    slug = props.location.state.slug;
    type = 'article_promotion';
  }
  const platformLookup = {
    WEB: '1',
    ANDROID: '2',
    IOS: '3'
  }
  const projectDetails = useContext(ProjectContext);
  const projectId = projectDetails.id;
  const [messageState, setMessageState] = useState("");
  const [messageerror, setMessageError] = useState("");
  const [categoryState,setCategoryState] = useState("");
  const [categoryError,setCategoryError] = useState("");
  const [webViewState,setWebViewState] = useState("");
  const [webViewError,setWebViewError] = useState("");  
  const [formdetails, setFormDetails] = useState({ title: projectDetails.name, message: message, projectid: projectId, guid: guid, slug: slug, type: type, platform: "", segmentId: "", messageService: 'firebase',cat_guid:"", webViewLink:"" });
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showWebView,setShowWebView] = useState(false)
  const [checkedPlatforms, setCheckedPlatforms] = useState([])

  useEffect(() => {
    (async () => {
      if (props.platform) {
        const availablePlatforms = props.platform;
        // console.log(availablePlatforms);
        setCheckedPlatforms([availablePlatforms[0]])
        setFormDetails({
          ...formdetails,
          platform: availablePlatforms[0]
        })
      }
    })()
  }, [])


  const handleChange = (event) => {

    setFormDetails({
      ...formdetails,
      [event.target.id]: (event.target.value),
    });
    setMessageError("")
    setMessageState("")
    if (event.target.id === "webViewLink") {
      setWebViewState("");
      setWebViewError("");
    }
  }
  const handleCategoriesChange = (category)=>{
    console.log(category)
    setFormDetails({
      ...formdetails,
      cat_guid : category.id,
      message: category.desc?category.desc:category.name
    })
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }
  const handleScreenTypeChange = (value) => {
    setFormDetails({
      ...formdetails,
      type:value,
      message:"",
      guid:"",
      slug:"",
      cat_guid:"",
      webViewLink:"",
    })
    setShowWebView(false)
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }

  const handleWebViewChange = (e) => {
    setShowWebView(e.target.checked);
  }


  const validateForm = async () => {
    let validated = true;
    if (formdetails.message.trim() === '') {
      setMessageState("has-danger");
      setMessageError("This field is required");
      validated = false;
    } else {

      if (formdetails.message.length > 160) {
        setMessageState("has-danger");
        setMessageError("Maximum 160 characters are allowed.");
        validated = false;
      } else {
        setMessageState("has-success");
        setMessageError("");
      }
    }
    if(formdetails.type==='category' && formdetails.cat_guid ===""){
      setCategoryState("has-danger");
      setCategoryError("Please Select Category")
    }
    if(showWebView && formdetails.webViewLink === ""){
      setWebViewState("has-danger");
      setWebViewError("Please enter a web link.");
      validated = false;
    }
    if (formdetails.type === 'general' && formdetails.webViewLink !== "") {

      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (!urlPattern.test(formdetails.webViewLink)) {
        setWebViewState("has-danger");
        setWebViewError("Please enter a valid URL.");
        validated = false;
      } else {
        setWebViewState("");
        setWebViewError("");
      }
    }

    if (!validated) { return false; }
   
    let platformString = "";
    // console.log(checkedPlatforms);
    for (let i in checkedPlatforms) {
      platformString += `${platformLookup[checkedPlatforms[i]]},`
    }
    // console.log("platform string : ", platformString);
    const formdata = {
      ...formdetails,
      platform: platformString
    }

    let sendNotification = await notificationsService.sendNotification(formdata);
    setShowLoader(true);
    setShowSaveButton(false);
    if (projectDetails.isContractExpired) {
      setShowLoader(false)
      setShowSaveButton(true)
      projectDetails.setContractExpiredAlert(projectDetails);
    }
    else if (sendNotification.status && sendNotification.data) {
      setTimeout(() => {
        setShowLoader(false)
        setAlert({ show: true, type: 'success', message: 'Notification was sent Successfully' });
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
        window.location.href = "/notifications/dashboard";
      }, 5000);


    } else {
      if (sendNotification.error) {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Due to Quota limit reached.Notification could not be sent.Upgrade plan.' });
        }, 1000);
      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Notification could not be sent, Please try again.' });
        }, 1000);
      }
      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 5000);
    }
  }
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }
  const handlePlatformChange = (value,checked) =>{
    if (value === "all" || value === "WEB") {
      if (checked) {
        setFormDetails({
          ...formdetails,
          type: "general"
        })
      }
    }  
    if (value == "all") {
      if (checked) {
        setCheckedPlatforms(props.platform);
      
      }
    } else {
      let newPlatforms;
      if (checked) {
        newPlatforms = checkedPlatforms.includes("all") ? [value] : [...checkedPlatforms, value];
      }
      else {
        newPlatforms = checkedPlatforms.filter(item => item !== value && item !== "all");
      }
      if (newPlatforms.length) {
        setCheckedPlatforms(newPlatforms)
      }
    }
  }
  let platFormElements = [];
  if (props.platform) {
    const availablePlatforms = props.platform;
    for (const key of availablePlatforms) {
        platFormElements.push(
          <div className='form-check fs'>
            <Label check key ={key}>
              <Input
                checked={checkedPlatforms.includes(key)} 
                value={key}
                id="platform"
                name="platform"
                type="checkbox"
                onChange={(e) => handlePlatformChange(key, e.target.checked)} 
              />
              {key} <span className="form-check-sign" />
            </Label>
          </div>
        )
    }
  }
  const screenTypeArray = [
    {
      label: "General",
      value: "general"
    },
   
    {
      label: "Live TV",
      value: "live_tv"
    },
    {
      label: "Unread Screen",
      value: "unread_screen"
    },
    {
      label: "Category Screen",
      value: "category"
    },
  ]

  let categoryElements = [];
  for (let category of screenTypeArray) {
    categoryElements.push(
      <div className='form-check-radio fs custom-radio'>
          <Label check >
            <Input
              checked={formdetails.type === (category.value)}
              value={formdetails.type}
              id="category"
              name="category"
              type="radio"
              onChange={(e) => handleScreenTypeChange(category.value)}
            />
            {category.label} <span className="form-check-sign" />
          </Label>
          
        </div>
    )
  }

  return (
    <>

      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="heding_dsh_h4">Send Push Notification </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontol form" action="" id="sendNotification" method="POST" onSubmit={handleSubmit}>
            <div className='consFom fulconsFom'>
              <Label className="lblNme">Platform</Label>
              <div className="inptFom mb0">
                <FormGroup>
                  <div className='form-check fs'>
                    <Label check key="all">
                      <Input
                        value="all"
                        id="platform"
                        name="platform"
                        type="checkbox"
                        onChange={(e) => handlePlatformChange("all", e.target.checked)}
                        checked={checkedPlatforms.length === props.platform.length}
                      />
                      ALL  <span className="form-check-sign" />
                    </Label>
                  </div>
                  {platFormElements}
                </FormGroup>
              </div>
            </div>
            {!(checkedPlatforms.includes("WEB")) && (
              <div className='consFom fulconsFom'>
              <Label className="lblNme">Target Screen</Label>
              <div className="inptFom mb0">
                <FormGroup>
                  {categoryElements}
                  {formdetails.type === 'category' && (
                    <div>
                      <Select
                        options={props.categories}
                        placeholder="Select Category"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCategoriesChange}
                      />
                      {categoryState === "has-danger" && (
                        <Label className="error" style={{ color: 'red' }}>
                          {categoryError}
                        </Label>
                      )}
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
            )}
            {(formdetails.type === "general" && (
              <div className='consFom fulconsFom '>
                <Label className="lblNme">Web Link</Label>
                <div className='inptFom mb2'>
                  <FormGroup>
                    <div className='form-check fs'>
                      <Label check>
                        <Input
                          id="webViewLink"
                          name="webViewLink"
                          type="checkbox"
                          onChange={handleWebViewChange}
                          checked={showWebView}
                        />
                        Send web link notification  <span className="form-check-sign" />
                      </Label>
                    </div>
                  </FormGroup>
                </div>
                {showWebView && (
                  <div className='inptFom'>
                    <FormGroup className={webViewState}>
                      <Input
                      name="webViewLink"
                      id="webViewLink"
                      type="url"
                      onChange={handleChange}
                      value={formdetails.webViewLink}
                      placeholder="Enter your link here..."
                      className="form-control"
                    />
                    {webViewState === "has-danger" ? (
                    <Label className="error">
                      {webViewError}
                    </Label>
                  ) : null}
                    </FormGroup>
                    
                  </div>)}
              </div>
            ))}

            <div className='consFom fulconsFom'>
              <Label className="lblNme">Message</Label>
              <div className="inptFom">
                <FormGroup className={messageState}>
                  <Input
                    name="message"
                    id="message"
                    type="textarea"
                    onChange={handleChange}
                    value={formdetails.message}
                    placeholder="Enter your message here..."
                  />
                  {messageState === "has-danger" ? (
                    <Label className="error">
                      {messageerror}
                    </Label>
                  ) : null}
                </FormGroup>
              </div>
            </div>

            <div className="dflLft">
              {showSaveButton ? <Button
                className="btn-round btnBlue"
                type="button"
                onClick={validateForm}
              >
                Send Notification
              </Button> : ''}
              {alert.show ? <Alert color={alert.type}>
                {alert.message}
              </Alert> : ''}
              {showLoader ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner> : ''}
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}
export default FirebaseCard