import React, { useState, useEffect, useContext } from 'react';
import{useHistory} from 'react-router-dom';
import ProjectContext from '../../ProjectContext';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import Pagination from 'react-js-pagination';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSearch, faSync, faPlusCircle, faTimes, faGamepad } from '@fortawesome/free-solid-svg-icons';

import gameServices from "../../services/api/games-service"

const GamesListing = (props) => {
const history = useHistory();
  const { id: projectId } = useContext(ProjectContext);

  const gameTypeOptions = [
    { value: 'headline_scramble', label: 'Headline Scramble' },
    { value: 'quiz', label: 'Quiz' },
    { value: 'poll', label: 'Poll' },
    { value: 'crossword', label: 'Crossword' },
  ];
  const gameTypeLookup = {
    "headline_scramble": "Headline Scramble",
    "quiz": "Quiz",
    "poll": "Poll",
    "crossword": "Crossword"
  }
  const [games, setGames] = useState([]);
  const [filters, setFilters] = useState({
    article_id: '',
    game_id: '',
    game_type: null,
    start_date: "",
    end_date: "",
  });
  const [formdata, setFormdata] = useState({
    article_id: '',
    game_id: '',
    game_type: null,
    start_date: null,
    end_date: null,
  })
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0
  });
  const [selectedGame, setSelectedGame] = useState(null);

  const [gameDetails, setGameDetails] = useState(null);
  const [isGameDetailsLoading, setIsGameDetailsLoading] = useState(false);
  const [gameDetailsError, setGameDetailsError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const firstRecord = (pagination.currentPage - 1) * pagination.pageSize + 1;
  const lastRecord = Math.min(pagination.currentPage * pagination.pageSize, pagination.totalItems);

  const fetchGames = async () => {

    if (!validateFilters()) return;

    setIsLoading(true);
    setError(null);
    try {
      const result = await gameServices.fetchGames({
        project_id: projectId,
        article_id: filters.article_id || undefined,
        game_id: filters.game_id || undefined,
        game_type: filters.game_type?.value || undefined,
        created_at: filters.created_at || undefined,
        start_date: filters.start_date || undefined,
        end_date: filters.end_date || undefined,
        page: pagination.currentPage,
        page_size: pagination.pageSize
      })
      if (result && result.status === false) {
        setGames([]);
        setError('No games found');
      } else {
        setGames(result.data.games || []);
        setPagination(prev => ({
          ...prev,
          totalItems: result.data.count || 0
        }));
      }
    } catch (err) {
      setError('Error fetching games');
      setGames([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGameDetails = async (gameId) => {
    setIsGameDetailsLoading(true);
    setGameDetailsError(null);
    setGameDetails(null);

    try {
      const response = await gameServices.fetchGameDetails({
        game_id: gameId,
        project_id: projectId
      });

      if (response.status === false) {
        setGameDetailsError('Unable to fetch game details');
      } else {
        setGameDetails(response.data.games[0]);
      }
    } catch (error) {
      console.error('Error fetching game details:', error);
      setGameDetailsError('An error occurred while fetching game details');
    } finally {
      setIsGameDetailsLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, [pagination.currentPage, formdata]);


  const openGameDetails = (game) => {
    setSelectedGame(game);
    setIsModalOpen(true);
    fetchGameDetails(game.game_id);
  };

  const handleSearch = () => {
    setFormdata({ ...formdata, filters })
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const handleReset = () => {
    setFormdata({
      article_id: '',
      game_id: '',
      game_type: null,
      start_date: null,
      end_date: null,
    });
    setFilters({
      article_id: '',
      game_id: '',
      game_type: null,
      start_date: null,
      end_date: null,
    })
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const validateFilters = () => {
    const errors = {};

    if (filters.start_date && filters.end_date) {
      const startDate = new Date(filters.start_date);
      const endDate = new Date(filters.end_date);

      if (startDate > endDate) {
        errors.end_date = 'End date must be after start date';
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };


  return (
    <div className="container-fluid p-4">
      <Card className="shadow-sm mb-4">
        <CardHeader className="text-black  p-3">
          <div className="d-flex align-items-center justify-content-between">
            <CardTitle
              tag="h2"
              className="mb-0 d-flex align-items-center"
              style={{ flexGrow: 1 }}
            >
              <FontAwesomeIcon icon={faGamepad} className="me-2" />
              Games
            </CardTitle>
            <Button
              color="primary"
              className="d-flex align-items-center"
              onClick={() => (history.push('/games/create'))}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
              Create Game
            </Button>
            <Button
              color="primary"
              className="d-flex align-items-center"
              onClick={() => (history.push('/games/settings'))}
            >
              <FontAwesomeIcon className="me-2" />
              Games Settings 
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <div className="row mb-3">
              <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted">Article ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter Article ID"
                    value={filters.article_id}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      article_id: e.target.value
                    }))}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted">Game ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter Game ID"
                    value={filters.game_id}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      game_id: e.target.value
                    }))}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted">Game Type</Label>
                  <Select
                    options={gameTypeOptions}
                    placeholder="Select Game Type"
                    value={filters.game_type}
                    onChange={(selected) => setFilters(prev => ({
                      ...prev,
                      game_type: selected
                    }))}
                  />
                </FormGroup>
              </div>

            </div>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted">Start Date</Label>
                  <Input
                    type="date"
                    value={filters.start_date || ''}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      start_date: e.target.value
                    }))}
                    invalid={!!validationErrors.end_date}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted">End Date</Label>
                  <Input
                    type="date"
                    value={filters.end_date || ''}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      end_date: e.target.value
                    }))}
                    invalid={!!validationErrors.end_date}
                  />
                  {validationErrors.end_date && (
                    <FormFeedback>{validationErrors.end_date}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4 d-flex align-items-end">
                <div>
                  <Button
                    color="primary"
                    onClick={handleSearch}
                    className="mr-2"
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleReset}
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon={faSync} className="mr-2" />
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : games.length === 0 ? (
            <div className="alert alert-info text-center">No games found</div>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div style={{ marginRight: '15px', marginBottom: "15px" }}>
                  <b>{firstRecord} - {lastRecord} of {pagination.totalItems}</b>
                </div>
                <Pagination
                  activePage={pagination.currentPage}
                  itemsCountPerPage={pagination.pageSize}
                  totalItemsCount={pagination.totalItems}
                  pageRangeDisplayed={3}
                  onChange={(pageNumber) => setPagination(prev => ({ ...prev, currentPage: pageNumber }))}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>


              <Table responsive hover striped>
                <thead className="thead-light">
                  <tr>
                    <th>S.No</th>
                    <th>Article</th>
                    {/* <th>Game ID</th> */}
                    <th>Game Type</th>
                    <th>Created On</th>
                    <th>Info</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((game, index) => {
                    return (
                      <tr key={game._id}>
                        <td>{(pagination.currentPage - 1) * pagination.pageSize + index + 1}</td>
                        <td style={{ maxWidth: '200px' }}>{game.article_id?.title}</td>
                        {/* <td>{game.game_id}</td> */}
                        <td>{gameTypeLookup[game.game_type] || ''}</td>
                        <td>
                          {new Date(game.createdAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}
                          <br />
                          {new Date(game.createdAt).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                          })}
                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => openGameDetails(game)}
                          >
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between align-items-center">
                <small className="text-muted">
                  Showing {games.length} of {pagination.totalItems} games
                </small>
              </div>
            </>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} size="lg">
        <ModalHeader className="bg-primary text-white">
          <h5 className="m-0 text-center w-100">Game Details</h5>
        </ModalHeader>
        <ModalBody>
          {isGameDetailsLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
              <p>Loading game details...</p>
            </div>
          ) : gameDetailsError ? (
            <div className="alert alert-danger">{gameDetailsError}</div>
          ) : gameDetails ? (
            <div className="container">
              <div className="row mb-4">
                <div className="col-12 text-center">
                  <h5>
                    {gameDetails.game_type === 'quiz'
                      ? 'Quiz Details'
                      : gameDetails.game_type === 'headline_shuffle'
                        ? 'Headline Shuffle Details'
                        : 'Game Details'}
                  </h5>
                  <hr className="my-3" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <strong>Game ID:</strong> <span>{gameDetails.id}</span>
                  </div>
                  <div className="mb-3">
                    <strong>Article ID:</strong> <span>{gameDetails.article_id}</span>
                  </div>
                  {/* Add more detailed rendering based on gameDetails */}
                  <div className="mb-4">
                    <h6>Game Data:</h6>
                    <pre className="p-3 bg-light rounded border">
                      {JSON.stringify(gameDetails.data, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GamesListing;